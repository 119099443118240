import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image"
import SEOComponent from "../components/global/seo";
import LayoutComponent from "../components/global/layout";

const AboutPage = () => {
  return (
      <LayoutComponent>
        <SEOComponent title="My Bookmarks" 
              metaTitle="My Bookmarks | Surinder Bhomra" 
              ogType={`article`} 
              metaDescription="A list of favourite websites I keep going back to."
              shareImageIsLarge={true} />
        <div className="divide-y">
          <header className="pt-6 pb-8 space-y-2 md:space-y-5">
            <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14"> 
              My Bookmarks <i className="twa twa-bookmark"></i>
            </h1>
            <p>I thought I'd share a small list of websites that I keep going back to that may also be of interest to you as well.</p>
          </header>
          <div className="flex justify-center">
            <div className="pt-8 pb-8 sm:pt-6 sm:pb-6 prose dark:prose-dark max-w-none xl:w-5/6">
              <p>If you like my website, then it's more than likely you'll like these websites as well. In no particular order:</p>
              <h2>jussiroine.com</h2>
              <p>
                This guy is a blogging machine, posting on a variety of subjects: Azure, Microsoft certifications, personal development/goals, 
                hardware reviews and anything that he deems relevant. Jussi is a natural born writer and the type of blogger I strive to be. 
                He has the ability to effortlessly write about a broad number of subjects in quick succession.
              </p>
              <p>Visit: <a href="https://jussiroine.com/" target="_blank" rel="noreferrer">jussiroine.com</a></p>
              <h2>joshwcomeau.com</h2>
              <p>
                Fun and aesthetically pleasing. Great technical blog presented in a creative way. There are subtle noises when certain interactions
                are made throughout the site giving a somewhat tactile experience. Each post contains a very cool custom-made like button - try it and see.  
              </p>
              <p>Visit: <a href="https://www.joshwcomeau.com/" target="_blank" rel="noreferrer">joshwcomeau.com</a></p>
              <h2>tyler.io</h2>
              <p>
                I keep going back mainly for the personal posts rather than the technical content, as I don't specialise in the area Tyler writes - 
                iOS apps. It was this website that inspired me to create this page. Take a look at Tyler's own list of "<a href="https://tyler.io/very-nice-websites/" target="_blank" rel="noreferrer">Very Nice Websites</a>".
              </p>
              <p>Visit: <a href="https://tyler.io/" target="_blank" rel="noreferrer">tyler.io</a></p>
              <h2>hanselman.com</h2>
              <p>
                This guy beams with positivity and has a wealth of info in Microsoft technologies as well as personal projects and musings. As a lost student fresh out of University looking for a technical field 
                to specialise in, Scott was the one who got me interested in .NET development and Microsoft-related technology. This website I'll truly hold dear.
              </p>
              <p>Visit: <a href="https://www.hanselman.com/" target="_blank" rel="noreferrer">hanselman.com</a></p>
              <h2>paulstamatiou.com</h2>
              <p>
                If the success of a blogger can be quantified by how they have influenced you, then this would be the one that heavily influenced me. Through his posts, I re-thought the 
                tech-stack of my website, photo storage workflow using Synology and photography inspiration.
              </p>
              <p>
                One thing you'll notice about Paul's content is that he doesn't publish posts on a regular basis. But when a post is published, it's something to take notice as the subject
                matter is written in such great detail - quality over quantity.
              </p>
              <p>Visit: <a href="https://paulstamatiou.com/" target="_blank" rel="noreferrer">paulstamatiou.com</a></p>
              <h2>zufrieden.io</h2>
              <p>I just happened to come across this site on <a href="https://lowwwcarbon.com/" target="_blank" rel="noreferrer">Lowwwcarbon.com</a> after my own site was added to their showcase of low carbon websites, 
                to which you can read about <a href="/Blog/2022/08/05/Websites-and-The-Environment">here</a>. Marc Friederich website is coded with sustainability in mind and provides 
                great content on the subject matter to make his online presenceas efficient as possible.
              </p>
              <p>
                Even though I find all content a great read, it's the links section I find the most interesting where Marc manages to find great nuggets from around the web to share.
              </p>
              <p>Visit: <a href="https://zufrieden.io/" target="_blank" rel="noreferrer">zufrieden.io</a></p>
            </div>
          </div>
        </div>
      </LayoutComponent>
  )
}

export const query = graphql`
  query {
    imageSharp(fluid: {originalName: {eq: "logo-me.jpg"}}) {
      fluid {
        src
        originalName
      }
      gatsbyImageData(width: 200, placeholder: BLURRED)
    }
  }
`

export default AboutPage
